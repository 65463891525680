@tailwind base;
@tailwind components;
@tailwind utilities;


.ted {
  border-radius: 50% 0 0 0;
  padding: 10px;
  background-color: white;
  margin: 0 -10px -10px 0;
}